import axios from "axios";

export default {
  async getLeads(userId) {
    const data = new FormData();
    data.append("user_id", userId);

    const response = await axios.post(`sales/leads/getFilteredLeads`, data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response);
    return response;
  },

  async updateLeadColumn(data) {
    const formData = new FormData();
    formData.append("leadInfo", JSON.stringify(data));

    const response = await axios.post(`sales/leads/updateLead`, formData, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response);
    return response;
  },

  async loadMessages(customer_id: string) {
    const formData = new FormData();
    formData.append("where[leads_messages_customer_id]", customer_id);
    formData.append("orderby", "leads_messages_creation_date");
    formData.append("orderdir", "asc");

    const response = await axios.post(`rest/v1/search/leads_messages`, formData, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response.data);
    return response.data;
  },

  async createMessage(data) {
    const response = await axios.post(`rest/v1/create/leads_messages`, data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response);
    return response.data;
  },

  async creaLead(data) {
    const formData = new FormData();
    formData.append("new_lead", JSON.stringify(data));

    const response = await axios.post(`sales/leads/createLead`, formData, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response);
    return response;
  },

  async saveLead(data) {
    const response = await axios.post("rest/v1/create/leads", data, {
      headers: {
        Authorization: process.env.VUE_APP_TOKEN,
      },
    });
    //console.log(response);
    return response;
  },
};
